import { Pane } from "evergreen-ui";
import { useResponsive } from "hooks";
export const MenuBox = ({ children }) => {
  const { responsive } = useResponsive();

  return (
    <Pane
      position="relative"
      display="flex"
      flexDirection="column"
      paddingTop={20}
      paddingLeft={20}
      width="fit-content"
      gap={25}
      paddingBottom={responsive.width < responsive.mobile ? 60 : 20}
    >
      {children}
    </Pane>
  );
};
