import { Pane } from "evergreen-ui";
import { TermsTitle, TermsOfUse, PrivacyPolicy, ContactUs } from "./components";

export const Terms = () => {
  return (
    <Pane className="terms_wrapper">
      <TermsTitle />
      <TermsOfUse />
      <PrivacyPolicy />
      <ContactUs />
    </Pane>
  );
};
