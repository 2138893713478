import {
  UserAvatar,
  MenuBox,
  MenuList,
  MenuFooter,
  LogoutBtn,
} from "./components";
import { SideSheet, Pane } from "evergreen-ui";

export const Menu = ({ showMenu, setShowMenu }) => {
  return (
    <>
      <SideSheet
        position="right"
        width={225}
        isShown={showMenu}
        onCloseComplete={() => setShowMenu(false)}
        containerProps={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
        }}
      >
        <Pane>
          <UserAvatar />
          <MenuBox>
            <MenuList />
          </MenuBox>
        </Pane>
        <MenuBox>
          <LogoutBtn />
        </MenuBox>
        <MenuFooter />
      </SideSheet>
    </>
  );
};
