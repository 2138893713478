import { Pane } from "evergreen-ui";
import { SmallText, ActionLabel } from "components/Base";


export const Footer = ({ setMode }) => {
  return (
    <Pane display="flex" flexDirection="column" gap={10} marginTop={10}>
      <SmallText textAlign="center">
        Already have an account?{" "}
        <ActionLabel onClick={() => setMode("login")}>Sign In.</ActionLabel>
      </SmallText>
    </Pane>
  );
};
