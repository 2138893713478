import { useContext, useEffect, useState } from "react";
import { GuideContext } from "context";
import { Pane } from "evergreen-ui";
import { PlayerBox, PlayPauseBtn, RestartBtn, SaveBtn } from "./componenets";

const audioElm = new Audio();
audioElm.id = 'audio-elm'
audioElm.type = "audio/mpeg";
audioElm.loop = false;
document.body.appendChild(audioElm);

export const AudioPlayer = () => {
  const { guide, showGuide } = useContext(GuideContext);
  const { audio } = guide;
  const [hover, setHover] = useState();
  const [isPlaying, setIsPlaying] = useState(false);
  const [currentTime, setCurrentTime] = useState(0);
  const [duration, setDuration] = useState(0);

  // toggle play button when guide view is closed
  if (!showGuide && isPlaying) {
    audioElm.pause();
    setIsPlaying(false);
  }

 

  // set base64 audio
  useEffect(() => {
    audioElm.src = audio;

    if (audio) {
      audioElm.play();
      audioElm.onplaying = () => {
        setIsPlaying(true);
      };
    }
  }, [audio]);

  useEffect(() => {
    const handleLoadedMetadata = () => {
      setDuration(audioElm.duration);
    };

    const handleTimeUpdate = () => {
      setCurrentTime(audioElm.currentTime);
    };

    const handlePause = () => {
      setIsPlaying(false);
    };

    const handleEnded = () => {
      setIsPlaying(false);
      audioElm.currentTime = 0;
    };

    audioElm.addEventListener("loadedmetadata", handleLoadedMetadata);
    audioElm.addEventListener("timeupdate", handleTimeUpdate);
    audioElm.addEventListener('pause', handlePause)
    audioElm.addEventListener("ended", handleEnded);

    // Remove event listeners when the component unmounts
    return () => {
      audioElm.removeEventListener("loadedmetadata", handleLoadedMetadata);
      audioElm.removeEventListener("timeupdate", handleTimeUpdate);
      audioElm.removeEventListener("ended", handleEnded);
    };
  }, []);

  // reset audio elm when dialog is closed
  useEffect(() => {
    if (!showGuide) {
      audioElm.currentTime = 0;
    }
  }, [showGuide]);

  return (
    <Pane>
      <PlayerBox height={60}>
        <PlayPauseBtn
          audioElm={audioElm}
          audio={audio}
          isPlaying={isPlaying}
          setIsPlaying={setIsPlaying}
          hover={hover}
          setHover={setHover}
        />
        <RestartBtn
          audioElm={audioElm}
          audio={audio}
          hover={hover}
          setHover={setHover}
        />
        <SaveBtn audio={audio} hover={hover} setHover={setHover} />
      </PlayerBox>

      <PlayerBox flexDirection="column" height={55}>
        <input
          style={{ cursor: "pointer" }}
          className="slider-x"
          type="range"
          width="100%"
          min="0"
          max={duration}
          value={currentTime}
          onChange={(e) => (audioElm.currentTime = e.target.value)}
        />
      </PlayerBox>
    </Pane>
  );
};
