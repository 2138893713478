import { windowRedirect } from "./windowRedirect";

export const openRoutes = ["/", "/auth", "/billing", "/terms"];

export const authRoutes = [];

export const isRoute = () => {
  const route = window.location.pathname;
  const routes = openRoutes.concat(authRoutes);

  if (!routes.includes(route)) {
    return windowRedirect();
  }
};
