import { Button } from "evergreen-ui";

export const AuthButton = ({ label, loading, ...rest }) => {
  return (
    <Button
      className={!loading ? "blue-btn" : "none"}
      autoFocus={false}
      appearance="primary"
      intent="success"
      width="100%"
      height={40}
      marginTop={5}
      fontWeight="bold"
      isLoading={loading}
      disabled={loading}
      {...rest}
    >
      {label}
    </Button>
  );
};
