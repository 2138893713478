import { useContext } from "react";
import { DialogContext } from "context";
import { Pwa } from "components/Base";
import { onParseUrl } from "utils";

export const useStart = () => {
  const { setDialog } = useContext(DialogContext);

  const pwa = onParseUrl("pwa");

  // show pwa instructions
  if (pwa) {
    setDialog((prev) => ({
      ...prev,
      refresh: false,
      isShown: true,
      title: <></>,
      children: <Pwa />,
    }));
  }
};
