import { Logo as NavLogo } from "components/Base";
import { Link } from "evergreen-ui";

export const Logo = () => {
  return (
    <Link href="/">
      <NavLogo textColor="white" fontSize="1.1rem" />
    </Link>
  );
};
