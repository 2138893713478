import { Pane } from "evergreen-ui";

export const PlayerBox = ({ children, ...rest }) => {
  return (
    <Pane width={270} paddingTop={30}>
      <Pane display="flex" justifyContent="space-between" {...rest}>
        {children}
      </Pane>
    </Pane>
  );
};
