import { useContext } from "react";
import { DialogContext } from "context";
import { Text } from "evergreen-ui";
import { Contact } from "components/Base";

export const ContactUs = () => {
  const { setDialog } = useContext(DialogContext);
  return (
    <Text
      cursor="pointer"
      fontSize="0.8rem"
      onClick={() => {
        setDialog((prev) => ({
          ...prev,
          isShown: true,
          title: "",
          children: <Contact />,
        }));
      }}
    >{`Contact`}</Text>
  );
};
