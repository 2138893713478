import { useContext } from "react";
import { Pane, Paragraph, Button } from "evergreen-ui";
import { DialogContext } from "context";
import { HowToBox } from "./components";

export const HowTo = ({getUserLocation = null}) => {
  const { setDialog } = useContext(DialogContext);
  return (
    <HowToBox>
      <Pane display="flex" flexDirection="column" gap={10}>
        <Paragraph fontSize="1.1rem" lineHeight={2}>
          Togogo provides AI generated audio tours of your surrounding area and
          beyond, simply tap place names and points of interest to start a tour.
        </Paragraph>
      </Pane>
      <Pane display="flex" justifyContent="flex-end">
        <Button
          width={150}
          appearance="primary"
          intent="success"
          fontWeight="bold"
          onClick={() => {
            setDialog((prev) => ({ ...prev, isShown: false }))
            if(getUserLocation){
              getUserLocation()
            }
          }}
        >
          Start Exploring!
        </Button>
      </Pane>
    </HowToBox>
  );
};
