import { useContext } from "react";
import { DialogContext } from "context";
import { Dialog, Pane, toaster } from "evergreen-ui";
import { windowRedirect } from "utils";

export const GlobalDialog = () => {
  const { dialog, setDialog } = useContext(DialogContext);
  const { isShown, title, refresh, children } = dialog;
  return (
    <Dialog
      isShown={isShown}
      title={title}
      onOpenComplete={() => {
        toaster.closeAll();
      }}
      onCloseComplete={() => {
        setDialog((prev) => ({ ...prev, isShown: false }));

        if (refresh) return windowRedirect();
      }}
      hasFooter={false}
    >
      <Pane>{children}</Pane>
    </Dialog>
  );
};
