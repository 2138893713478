import { Pane, SearchIcon } from "evergreen-ui";
import { theme } from "theme";
export const SearchFloating = ({ setShowSearch }) => {
  const { colors } = theme;
  return (
    <Pane
      zIndex={1}
      elevation={4}
      cursor="pointer"
      position="fixed"
      bottom={35}
      right={25}
      display="flex"
      justifyContent="center"
      alignItems="center"
      padding={18}
      borderRadius="50%"
      background={colors.primary}
      onClick={() => setShowSearch(true)}
    >
      <SearchIcon color={colors.white} size={30} />
    </Pane>
  );
};
