import { IconButton, StepBackwardIcon } from "evergreen-ui";
import { CustomTooltip } from "components/Base";
import { theme } from "theme";
const { colors } = theme;
export const RestartBtn = ({
  audioElm,
  audio,
  hover,
  setHover,
}) => {
  return (
    <CustomTooltip id="Back" tip="Back" hover={hover} top={-50} left={-7}>
      <IconButton
        disabled={!audio}
        opacity={audio ? 1 : 0.1}
        onMouseEnter={() => setHover("Back")}
        onMouseLeave={() => setHover("default")}
        icon={<StepBackwardIcon size={25} color={colors.black} />}
        padding={20}
        onClick={() => {
          setHover("default");
          audioElm.currentTime = 0;
        }}
      />
    </CustomTooltip>
  );
};
