import { useState } from "react";
import { FullPageLoader } from "components/Base";
import { MainBox, ProjectMap, GuideDialog, MapFooter } from "./components";

export const Main = () => {
  const [loading, setLoading] = useState(true);
  
  return (
    <MainBox>
      {loading ? <FullPageLoader /> : null}
      <ProjectMap loading={loading} setLoading={setLoading} />
      <GuideDialog slide="slide" />
      <MapFooter />
    </MainBox>
  );
};
