import { useContext } from "react";
import {
  Pane,
  Text,
  Dialog,
  CrossIcon
} from "evergreen-ui";
import { GuideContext } from "context";
import { isMobile } from "utils";
import { theme } from "theme";

export const VideoDialog = ({ showVideo, setShowVideo }) => {
  const { guide } = useContext(GuideContext);
  return (
    <Dialog
      title={guide.title}
      isShown={showVideo}
      onCloseComplete={() => setShowVideo(false)}
      hasFooter={false}
      hasHeader={false}
      contentContainerProps={{
        background: theme.colors.white,
        padding: 0,
        borderRadius: 5,
      }}
      overlayProps={{ background: theme.colors.black }}
    >
      {!isMobile ? (
        <Pane
          cursor="pointer"
          zIndex={10}
          position="absolute"
          top={-45}
          right={-45}
          display="flex"
          justifyContent="center"
          alignItems="center"
          padding={10}
          borderRadius={50}
          background={theme.colors.white}
          onClick={() => setShowVideo(false)}
        >
          <CrossIcon color={theme.colors.black} />
        </Pane>
      ) : null}

      <iframe
        width="100%"
        height="350"
        src={guide.video}
        title="Video Player"
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        referrerPolicy="strict-origin-when-cross-origin"
        allowFullScreen
        style={{
          borderRadius: 5,
          borderBottomLeftRadius: 0,
          borderBottomRightRadius: 0,
        }}
      />

      <Pane
        display="flex"
        justifyContent="flex-end"
        alignItems="center"
        gap={5}
        paddingRight={10}
        marginTop={-5}
      >
        <Text fontSize={10} color={theme.colors.black}>
          Source: YouTube
        </Text>
      </Pane>
    </Dialog>
  );
};
