import { Pane } from "evergreen-ui";
import { theme } from "theme";

export const AvatarOuterBox = ({ children }) => {
  const { colors } = theme;
  return (
    <Pane
      elevation={2}
      display="flex"
      flexDirection="column"
      gap={20}
      background={colors.black}
      paddingY={20}
      paddingX={15}
    >
      {children}
    </Pane>
  );
};
