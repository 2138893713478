import { Pane } from "evergreen-ui";
import { isMobile } from "utils";

export const TourHeaderBox = ({ children }) => {
  return (
    <Pane
      id="view-image"
      width="100%"
      maxWidth={600}
      display="flex"
      justifyContent="center"
      gap={25}
      background="rgba(255,255,255,0.1)"
      paddingY={15}
      borderRadius={isMobile ? 0 : 5}
      borderBottomLeftRadius={0}
      borderBottomRightRadius={0}
      borderBottom={`solid grey 1px`}
      elevation={1}
    >
      {children}
    </Pane>
  );
};
