import { useContext } from "react";
import { Pane, Text, Dialog, CrossIcon } from "evergreen-ui";
import { GuideContext } from "context";
import { isMobile } from "utils";
import { theme } from "theme";

export const ImageDialog = ({ image, showImage, setShowImage }) => {
  const { guide } = useContext(GuideContext);
  return (
    <Dialog
      title={guide.title}
      isShown={showImage}
      onCloseComplete={() => setShowImage(false)}
      hasFooter={false}
      hasHeader={false}
      contentContainerProps={{
        background: theme.colors.white,
        padding: 0,
        borderRadius: 5,
      }}
      overlayProps={{ background: theme.colors.black }}
    >
      {!isMobile ? (
        <Pane
          cursor="pointer"
          zIndex={10}
          position="absolute"
          top={-45}
          right={-45}
          display="flex"
          justifyContent="center"
          alignItems="center"
          padding={10}
          borderRadius={50}
          background={theme.colors.white}
          onClick={() => setShowImage(false)}
        >
          <CrossIcon color={theme.colors.black} />
        </Pane>
      ) : null}
      <img
        alt={`${process.env.REACT_APP_BRAND}`}
        width="100%"
        src={image}
        style={{
          height: 350,
          borderRadius: 5,
          borderBottomLeftRadius: 0,
          borderBottomRightRadius: 0,
        }}
      />

      <Pane paddingRight={10} marginTop={-5} textAlign="right">
        <Text fontSize={10} color={theme.colors.black}>
          Source: Google
        </Text>
      </Pane>
    </Dialog>
  );
};
