import { useEffect, useState } from "react";
import { Pane } from "evergreen-ui";
import { Create, Login, Recover } from ".";
import { onTracker, onParseUrl } from "utils";

export const Auth = ({ redirect }) => {
  const [mode, setMode] = useState("create");

  useEffect(() => {
    // analytics
    onTracker("onAuthLoaded()");

    localStorage.removeItem("session");
    const initMode = onParseUrl("m");
    if (initMode) {
      setMode(initMode);
    }
  }, []);

  return (
    <Pane
      display="flex"
      justifyContent="center"
      alignItems="center"
      paddingTop={100}
      paddingBottom={200}
    >
      <Pane
        display="flex"
        width={400}
        minHeight={600}
        flexDirection="column"
        alignItems="center"
        gap={22}
        paddingY={20}
        paddingX={20}
        border="solid whitesmoke 1px"
        background="white"
        borderRadius={5}
      >
        {mode === "create" ? (
          <Create redirect={redirect} setMode={setMode} />
        ) : null}
        {mode === "login" ? (
          <Login redirect={redirect} setMode={setMode} />
        ) : null}
        {mode === "recover" ? (
          <Recover redirect={redirect} setMode={setMode} />
        ) : null}
      </Pane>
    </Pane>
  );
};
