import { useContext } from "react";
import { GuideContext } from "context";
import { Pane, Text, LinkIcon as ShareImg } from "evergreen-ui";
import { onShare } from "utils";

import { theme } from "theme";

export const ShareIcon = () => {
  const { guide } = useContext(GuideContext);

  return (
    <Pane
      cursor="pointer"
      display="flex"
      alignItems="center"
      gap={5}
      onClick={() =>
      
        onShare(
          `${process.env.REACT_APP_BASE_URL}?share=${guide.id}`
        )
      }
    >
      <ShareImg size={14} color={theme.colors.primary} />
      <Text color={theme.colors.white} fontSize={12}>
        Share
      </Text>
    </Pane>
  );
};
