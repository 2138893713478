import { CrossIcon } from "evergreen-ui";

export const ClearBtn = ({ search, setSearch }) => {
  return search ? (
    <CrossIcon
      cursor="pointer"
      color="grey"
      size={15}
      onClick={() => setSearch("")}
    />
  ) : null;
};
