import { useEffect, useState } from "react";
import { Pane, Text } from "evergreen-ui";
import { isPwa, isMobile } from "utils";
import { theme } from "theme";

export const TourFooter = () => {
  const [atBottom, setAtBottom] = useState(false);

  useEffect(() => {
    const scrollableContainer = document.getElementById("scroll-box");

    const handleScroll = () => {
      if (
        scrollableContainer.scrollTop ===
        scrollableContainer.scrollHeight - scrollableContainer.clientHeight
      ) {
        // Scroll has reached the bottom
        setAtBottom(true);
      } else {
        setAtBottom(false);
      }
    };

    scrollableContainer.addEventListener("scroll", handleScroll);

    // Clean up the event listener when the component unmounts
    return () => {
      scrollableContainer.removeEventListener("scroll", handleScroll);
    };
  }, []); 
  
  return atBottom ? (
    <Pane
      position="absolute"
      bottom={0}
      width="100%"
      display="flex"
      justifyContent="center"
      alignItems="center"
      background={theme.colors.black}
    >
      <Pane
        width="100%"
        maxWidth={600}
        display="flex"
        justifyContent="center"
        alignItems="center"
        background="rgba(255,255,255,0.1)"
        paddingTop={isPwa && isMobile ? 10 : 2}
        paddingBottom={isPwa && isMobile ? 50 : 2}
      >
        <Text color="grey" fontSize={10}>
          © {new Date().getFullYear()} {process.env.REACT_APP_BRAND}
        </Text>
      </Pane>
    </Pane>
  ) : null;
};
