import { SmallText, ActionLabel } from "components/Base";

export const Footer = ({ setMode }) => {
  return (
    <>
      <SmallText
      fontSize={12}
        cursor="pointer"
        color="grey"
        textAlign='center'
        onClick={() => setMode("recover")}
      >
        Forgot password?
      </SmallText>
      <SmallText marginTop={10} textAlign="center">
        Don't have an account?{" "}
        <ActionLabel onClick={() => setMode("create")}>
          Create an account.
        </ActionLabel>
      </SmallText>
    </>
  );
};
