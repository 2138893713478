import {
  FooterCard,
  ContactUs,
  TermsLink,
  Copywrite,
} from "./components";

export const MenuFooter = () => {
  return (
    <>
      <FooterCard>
        <Copywrite />
        <TermsLink />
        <ContactUs />
      </FooterCard>
    </>
  );
};
