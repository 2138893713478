import { Dialog, Pane } from "evergreen-ui";
import { theme } from "theme";
export const SearchDialog = ({
  children,
  showSearch,
  setShowSearch,
  setSearch,
  loading,
}) => {
  const { colors } = theme;
  return (
    <Dialog
      isShown={showSearch}
      hasFooter={false}
      onCloseComplete={() => {
        setShowSearch(false);
        setSearch("");
      }}
      containerProps={{ marginTop: "100px",  background: "#262626 !important" }}
    >
      <Pane display="flex" justifyContent="center" paddingBottom={80}>
        <Pane
          position="relative"
          elevation={4}
          width={300}
          display="flex"
          alignItems="center"
          gap={5}
          paddingX={15}
          paddingY={5}
          borderRadius={25}
          background={loading ? "whitesmoke" : "white"}
          border={`solid ${colors.white} 2px`}
        >
          {children}
        </Pane>
      </Pane>
    </Dialog>
  );
};
