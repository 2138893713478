import { TourBox, GuideContent, TourHeader, TourFooter } from "./components";


export const Tour = () => {
  return (
    <>
      <TourHeader />
      <TourBox>
        <GuideContent />
      </TourBox>
     <TourFooter/>
    </>
  );
};
