import { Pane } from "evergreen-ui";
import { useResponsive } from "hooks";
export const FooterCard = ({ children }) => {
  const { responsive } = useResponsive();
  return (
    <Pane
      overflowY="scroll"
      position="fixed"
      display={responsive.height < responsive.mobile ? "none" : "flex"}
      bottom={0}
      width="100%"
      justifyContent="center"
      gap={5}
      paddingBottom={responsive.width < responsive.mobile ? 30 : 20}
      paddingTop={18}
      borderTop='solid lightgrey 1px'
    >
      {children}{" "}
    </Pane>
  );
};
