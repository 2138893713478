import { useEffect, useState } from "react";
import ReactGA from "react-ga4";

export const useGoogleAnalytics = () => {
  const [isInitialized, setIsInitialized] = useState(false);
  useEffect(() => {
    ReactGA.initialize(`${process.env.REACT_APP_GOOGLE_ANALYTICS}`);
    ReactGA.send("pageview");
    setIsInitialized(true);
  }, []);
  return isInitialized;
};
