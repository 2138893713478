import { useContext } from "react";
import { GuideContext } from "context";
import { Pane, CrossIcon as CloseIcon } from "evergreen-ui";
import { scrollIntoView } from "utils";
import { theme } from "theme";
const { colors } = theme;
export const CloseBtn = () => {
  const { setShowGuide } = useContext(GuideContext);
  return (
    <Pane
      zIndex={100}
      position="absolute"
      display="flex"
      justifyContent="center"
      alignItems="center"
      cursor="pointer"
      top={15}
      right={10}
      padding={15}
      onClick={() => {
        scrollIntoView('view-image')
        setShowGuide(false)
      }}
    >
      <CloseIcon color={colors.black} size={25} />
    </Pane>
  );
};
