import { Heading, Text, Label } from "evergreen-ui";
import { theme } from "theme";
const { fonts, colors } = theme;

export const AuthHeading = ({ title, ...rest }) => {
  return (
    <Heading
      fontSize={25}
      lineHeight={2}
      textAlign="center"
      {...rest}
    >
      {title}
    </Heading>
  );
};

export const SmallText = ({ children, ...rest }) => {
  return (
    <Text fontSize={14} {...rest}>
      {children}
    </Text>
  );
};

export const ActionLabel = ({ children, ...rest }) => {
  return (
    <Label
      cursor="pointer"
      fontSize={fonts.small}
      color={colors.blue}
      {...rest}
    >
      {children}
    </Label>
  );
};
