import { AppBox } from "./AppBox";
import { AppRoutes } from "./AppRoutes";
import { GuideProvider, DialogProvider } from "context";
import { useGoogleAnalytics } from "hooks";

export const App = () => {
   useGoogleAnalytics();

  return (
    <GuideProvider>
      <DialogProvider>
        <AppBox>
          <AppRoutes />
        </AppBox>
      </DialogProvider>
    </GuideProvider>
  );
};
