import { Pane, TextInput } from "evergreen-ui";
import { InputLabel } from "..";

export const PrimaryInput = ({
  id,
  type,
  labelColor,
  label,
  tip,
  error,
  disabled,
  hover,
  ...rest
}) => {
  return (
    <Pane width='100%' display="flex" flexDirection="column" gap={5}>
      <InputLabel id={id} labelColor={labelColor} label={label} hover={hover} tip={tip} />
      <TextInput
        width='100%'
        border="solid lightgrey 1px"
        maxLength={60}
        type={type}
        label=""
        disabled={disabled}
        isInvalid={error}
        height={38}
        {...rest}
      />
    </Pane>
  );
};
