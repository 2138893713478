import { useState, useContext } from "react";
import { GuideContext } from "context";
import { onSearch } from "api-request";
import {
  SearchFloating,
  SearchDialog,
  SearchInput,
  SearchBtn,
  ClearBtn,
} from "./components";

export const MapSearch = ({ setHidePin, setCenter, loading, setLoading }) => {
  const { setGuide } = useContext(GuideContext);
  const [search, setSearch] = useState("");
  const [showSearch, setShowSearch] = useState(false);

  const handleSearch = async () => {
    return await onSearch(search, setCenter, setGuide, setShowSearch, setHidePin);
  };

  return (
    <>
      <SearchFloating setShowSearch={setShowSearch} />
      <SearchDialog
        showSearch={showSearch}
        setShowSearch={setShowSearch}
        setSearch={setSearch}
        loading={loading}
      >
        <SearchInput
          search={search}
          setSearch={setSearch}
          loading={loading}
          setLoading={setLoading}
          handleSearch={handleSearch}
        />

        <ClearBtn search={search} setSearch={setSearch} />
        <SearchBtn
          loading={loading}
          setLoading={setLoading}
          handleSearch={handleSearch}
        />
      </SearchDialog>
    </>
  );
};
