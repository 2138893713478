import { useContext } from "react";
import { GuideContext } from "context";
import { Pane, Text, Spinner } from "evergreen-ui";

export const AudioLoading = () => {
  const { guide } = useContext(GuideContext);
  
  return !guide?.message ? (
    <Pane display="flex" justifyContent="center" alignItems="center" gap={5}>
      <Spinner size={18} />
      <Text className="blink" color="white" fontSize={12}>
        {" "}
        Preparing Audio
      </Text>
    </Pane>
  ) : null;
};
