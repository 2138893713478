import { Label } from "evergreen-ui";
import { SmallText } from "components/Base";

import { theme } from 'theme'
const { fonts, colors } = theme

export const Footer = ({ setMode }) => {
  return (
    <SmallText marginTop={10}>
      Remember your password?{" "}
      <Label
        cursor="pointer"
        fontSize={fonts.small}
        color={colors.blue}
        onClick={() => setMode("login")}
      >
        Login.
      </Label>
    </SmallText>
  );
};
