import { useContext } from "react";
import { GuideContext } from "context";
import { Pane, Link, Text, MapMarkerIcon as MapIcon } from "evergreen-ui";
import { theme } from "theme";

export const GoogleMapsLink = () => {
 
  const { userLocation, guide } = useContext(GuideContext);
  const { placeId, coordinates, title } = guide;
  
  const createGoogleMapsDirectionLink = (startAddress, endAddress) => {
    const googleMapsURL = "https://www.google.com/maps/dir/";

    // Encode the start and end addresses
    const encodedStart = encodeURIComponent(startAddress);
    const encodedEnd = encodeURIComponent(endAddress);

    // Combine the URL with encoded addresses
    const directionsURL = `${googleMapsURL}${encodedStart}/${encodedEnd}`;

    return directionsURL;
  };

  // Example usage
  const startAddress = `${userLocation[0]},${userLocation[1]}`;
  const endAddress = `${coordinates[0]},${coordinates[1]}`;
  const googleMapsLink = createGoogleMapsDirectionLink(
    startAddress,
    endAddress
  );

  const href = placeId
    ? `https://www.google.com/maps/search/?api=1&query=${coordinates[0]},${coordinates[1]}&query_place_id=${placeId}`
    : `https://www.google.com/maps/search/?api=1&query=${title
        .split(" ")
        .join("+")}`;

  return (
    <Link href={googleMapsLink || href} target="_blank">
      <Pane
        cursor="pointer"
        display="flex"
        alignItems="center"
        gap={5}
      >
        <MapIcon size={16} color={theme.colors.primary} />
        <Text color={theme.colors.white} fontSize={12}>
          Directions
        </Text>
      </Pane>
    </Link>
  );
};
