import { Text } from "evergreen-ui";
import { theme } from "theme";

export const UnderlinedLabel = ({ children }) => {
  const { colors } = theme;
  return (
    <Text
      fontSize="1.5rem"
      fontWeight="bold"
      borderBottom={`solid ${colors.primary} 2px`}
      paddingBottom={10}
      lineHeight={1.4}
    >
      {children}
    </Text>
  );
};
