import { Heading } from "evergreen-ui";

export const EllipsisHeading = ({ children, ...rest }) => {
  return (
    <Heading
      textOverflow="ellipsis"
      whiteSpace="nowrap"
      overflow="hidden"
      {...rest}
    >
      {children}
    </Heading>
  );
};
