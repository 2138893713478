import { useContext } from "react";
import { GuideContext } from "context";
import { EllipsisHeading } from "components/Base";
import { TitleBox } from "./component";
import { theme } from "theme";
const { colors } = theme;

export const Title = () => {
  const { guide } = useContext(GuideContext);
 
  return (
    <TitleBox >
      <EllipsisHeading
        fontSize="1.3rem"
        color={colors.white}
        textAlign="center"
        padding={10}
      >
        {guide?.label}
      </EllipsisHeading>
   
    </TitleBox>
  );
};
