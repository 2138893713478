import { Pane, Spinner } from "evergreen-ui";
import { theme } from "theme";

export const FullPageLoader = ({ children, ...rest }) => {
  const { colors } = theme;
  return (
    <Pane
      zIndex={9}
      position="fixed"
      top={0}
      left={0}
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      width="100%"
      height="100vh"
      maxHeight="100%"
      overflow="hidden"
      background={colors.black}
      opacity='0.8'
      {...rest}
    >
      <Spinner />
    </Pane>
  );
};
