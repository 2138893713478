import { constants } from "constants";
import { Text } from "evergreen-ui";
export const Copywrite = () => {
  const { brand } = constants
  return (
    <Text fontSize="0.8rem">
    © {brand}
  </Text>
  );
};
