import { useContext } from "react";
import { GuideContext } from "context";
import { Pane, Text } from "evergreen-ui";
import { isPwa, isMobile } from "utils";
import { constants } from "constants";
import { theme } from "theme";


export const MapFooter = () => {
  const { brand } = constants;
  const { showGuide } = useContext(GuideContext);

  if (showGuide) return null;
  return (
    <Pane
      cursor="pointer"
      zIndex={0}
      position="fixed"
      left={0}
      bottom={0}
      width="100%"
      height={23}
      background={theme.colors.black}
      display="flex"
      justifyContent="center"
      alignItems={isPwa && isMobile ? 'flex-start' : 'center'}
      paddingTop={isPwa && isMobile  ? 10 : 0}
      paddingBottom={isPwa && isMobile  ? 50 : 0}

    >
     <Text fontSize={10} color='whitesmoke'>
      © {new Date().getFullYear()} {brand}
     </Text>
    </Pane>
  );
};
