import { Pane, SearchIcon } from "evergreen-ui";
import { theme } from "theme";
export const SearchBtn = ({loading, setLoading, handleSearch }) => {
  const { colors } = theme;
  return (
    <Pane
      cursor="pointer"
      position="absolute"
      right={-2}
      display="flex"
      justifyContent="center"
      alignItems="center"
      background={colors.primary}
      width={46}
      height={46}
      borderRadius={25}
    >
      <Pane
        onClick={async () => {
          if (loading) return;
          setLoading(true);
          await handleSearch();
          setLoading(false);
        }}
      >
        {" "}
        {!loading ? <SearchIcon size={20} color={colors.white} /> : <Pane className="btn-spinner"/> }
      </Pane>
    </Pane>
  );
};
