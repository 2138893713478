import { createContext, useState } from "react";

export const DialogContext = createContext(null);

export const DialogProvider = ({ children }) => {
  const [dialog, setDialog] = useState({
    isShown: false,
    refresh: false,
    title: "",
    children: <></>,
  });

  const { Provider } = DialogContext;

  return <Provider value={{ dialog, setDialog }}>{children}</Provider>;
};
