import { useContext } from "react";
import { GuideContext } from "context";
import { Pane } from "evergreen-ui";
import { GuideBox, Title, Audio, Tour, CloseBtn } from "./components";

export const GuideDialog = ({ slide }) => {
  const { guide, showGuide } = useContext(GuideContext);

  return (
    <Pane className={showGuide ? slide : "hide"}>
      {guide ? (
        <GuideBox>
          <CloseBtn />
          <Title />
          <Audio />
          <Tour />
        </GuideBox>
      ) : (
        <></>
      )}
    </Pane>
  );
};
