import axios from "axios";
import { toaster } from "evergreen-ui";
import { onTracker, parseCoords, windowRedirect } from "utils";

export const onInit = async (input) => {
  // analytics
  onTracker("onInit()");

  // soft validation
  if (!input) return;

  try {
    const { data } = await axios.post(`api/connect/init`, { input });
    const { error } = data;
    if (error) throw new Error();
    if (!data?.user) {
      localStorage.removeItem("session");
    }

    return data;
  } catch (e) {
    toaster.danger("Location Error", {
      id: "xyz",
      description: "Unable to find information on your current location.",
      duration: 10,
    });
    return false;
  }
};

export const onSearch = async (
  input,
  setCenter,
  setGuide,
  setShowSearch,
  setHidePin
) => {
  // analytics
  onTracker("onSearch()");

  // soft validation
  if (!input) return;

  try {
    const { data } = await axios.post(`api/connect/search`, { input });

    const { error } = data;
    if (error) throw new Error();

    if (data === "login") {
      return windowRedirect("auth?m=login");
    }

    if (data === "billing") {
      return windowRedirect("billing");
    }

    setCenter(parseCoords(data.coordinates));
    setShowSearch(false);

    if (!data.municipality && !data.region && data.country || !data.municipality && data.region && data.country) {
      setHidePin(false);
      return setGuide(data);
    } else {
      setHidePin(true);
      return setGuide(data);
    }
  } catch (e) {
    return toaster.danger("Search Error", {
      id: "xyz",
      description: "Try a more specific address.",
      duration: 10,
    });
  }
};

export const onSearchPoi = async (input) => {
  // analytics
  onTracker("onSearchPoi()");

  // soft validation
  if (!input) return;

  try {
    const { data } = await axios.post(`api/connect/search-poi`, { input });

    const { error } = data;
    if (error) throw new Error();

    if (data === "login") {
      return windowRedirect("auth?m=login");
    }

    if (data === "billing") {
      return windowRedirect("billing");
    }

    return data;
  } catch (e) {
    toaster.danger("Search Error", {
      id: "xyz",
      description: "Unable to find information on your selected location.",
      duration: 10,
    });

    return false;
  }
};

export const onSearchCoords = async (input) => {
  // analytics
  onTracker("onSearchCoords()");

  // soft validation
  if (!input) return;

  try {
    const { data } = await axios.post(`api/connect/search-coords`, { input });

    const { error } = data;
    if (error) throw new Error();

    if (data === "login") {
      return windowRedirect("auth?m=login");
    }

    if (data === "billing") {
      return windowRedirect("billing");
    }

    return data;
  } catch (e) {
    toaster.danger("Search Error", {
      id: "xyz",
      description: "Unable to find information on your selected location.",
      duration: 10,
    });
    return false;
  }
};
