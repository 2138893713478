import { useContext, useState } from "react";
import { GuideContext } from "context";
import axios from "axios";
import { toaster } from "evergreen-ui";

import {
  TourHeaderBox,
  ImageIcon,
  GoogleMapsLink,
  ImageDialog,
  VideoDialog,
  AudioLoading,
  ShareIcon,
} from "./components";

export const TourHeader = () => {
  const { guide } = useContext(GuideContext);
  const [loading, setLoading] = useState(false);
  const [image, setImage] = useState("");
  const [showImage, setShowImage] = useState(false);
  const [showVideo, setShowVideo] = useState(false);

  const onViewImages = async () => {
    try {
      if (!guide?.placeId) throw new Error();

      if (guide?.video) {
        document.getElementById('audio-elm').pause()
        return setShowVideo(true);
      }

      const input = {
        title: guide?.title,
        region: guide?.region,
        country: guide?.country,
      };

      setLoading(true);
      const { data } = await axios.post(`api/connect/images`, { input });

      const { error } = data;
      if (error) throw error;

      setLoading(false);
      setShowImage(true);
      setImage(data);
    } catch (e) {
      setLoading(false);
      toaster.warning("Unable to provide an image for this location.", {
        id: "xyz",
        duration: 12,
      });
    }
  };

  return (
    <>
      <TourHeaderBox>
        {guide?.message ? (
          <>
            <ImageIcon
              loading={loading}
              onViewImages={onViewImages}
              isVideo={!!guide?.video}
            />
            <GoogleMapsLink />
            <ShareIcon />
          </>
        ) : (
          <AudioLoading />
        )}
      </TourHeaderBox>
      <ImageDialog
        image={image}
        showImage={showImage}
        setShowImage={setShowImage}
      />

      <VideoDialog showVideo={showVideo} setShowVideo={setShowVideo} />
    </>
  );
};
