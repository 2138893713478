import { Pane, Heading, toaster, Link } from "evergreen-ui";
import { isPwa } from "utils";
import { theme } from "theme";

export const Contact = () => {
  const { colors } = theme;

  const copyEmail = (link) => {
    navigator.clipboard.writeText(link);
    return toaster.success(`Copied`, {
      id: "xyz",
      description: `${link}`,
      duration: 8,
    });
  };

  return (
    <Pane display="flex" flexDirection="column" gap={25} paddingBottom={90}>
      <Heading fontSize="2rem">Contact</Heading>
      {isPwa ? (
        <Heading
          cursor="pointer"
          width="fit-content"
          color={colors.primary}
          fontSize="1.5rem"
          textDecoration="underline"
          onClick={() => copyEmail(process.env.REACT_APP_EMAIL)}
        >
          {process.env.REACT_APP_EMAIL}
        </Heading>
      ) : (
        <Link
          width="fit-content"
          textDecoration="none"
          href={`mailto:${process.env.REACT_APP_EMAIL}`}
        >
          <Heading
            cursor="pointer"
            width="fit-content"
            color={colors.primary}
            fontSize="1.5rem"
            textDecoration="underline"
          >
            {process.env.REACT_APP_EMAIL}
          </Heading>
        </Link>
      )}
    </Pane>
  );
};
