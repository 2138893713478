import { TextInput } from "evergreen-ui";
export const SearchInput = ({
  search,
  setSearch,
  loading,
  setLoading,
  handleSearch
}) => {
  return (
    <TextInput
      width="78%"
      appearance="minimal"
      fontSize="0.9rem"
      placeholder="Search location. . ."
      disabled={loading}
      autoFocus
      maxLength={50}
      value={search}
      onChange={(e) => setSearch(e.target.value)}
      onKeyDown={async (e) => {
        if (e.key === "Enter") {
          setLoading(true);
          await handleSearch();
          setLoading(false);
        }
      }}
    />
  );
};
