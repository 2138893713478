import { InfoSignIcon, Pane } from "evergreen-ui";

export const PwaIcon = ({ ...rest }) => {
  return (
    <Pane display="flex" flexDirection='column' justifyContent="center" alignItems="center" {...rest}>
      <Pane width='100%'  textAlign='right'>
      <InfoSignIcon color='purple' textAlign='right'/>
      </Pane>
      <img
      alt={`${process.env.REACT_APP_BRAND} pwa`}
        src="./assets/togogo-pwa.png"
        width={150}
        height={42}
      />
    </Pane>
  );
};
