import { Pane, Text, MediaIcon, Spinner } from "evergreen-ui";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faYoutube } from "@fortawesome/free-brands-svg-icons";

import { theme } from "theme";

export const ImageIcon = ({ loading, onViewImages, isVideo }) => {
 
  return (
    <Pane
      cursor="pointer"
      display="flex"
      alignItems="center"
      gap={5}
      onClick={() => onViewImages()}
    >
      {" "}
      {!loading ? (
        !isVideo ? (
          <MediaIcon size={16} color={theme.colors.primary} />
        ) : (
          <FontAwesomeIcon size="1x" color="red" icon={faYoutube} />
        )
      ) : (
        <Spinner size={18} />
      )}
      <Text color={theme.colors.white} fontSize={12}>
        {!isVideo ? "Image" : "YouTube"}
      </Text>
    </Pane>
  );
};
