import { useState } from "react";
import axios from "axios";
import { Alert, Text } from "evergreen-ui";
import {
  PrimaryInput,
  AuthLogo,
  AuthButton,
  AuthHeading,
} from "components/Base";
import { Footer } from "./Footer";
import { errorHandler, windowRedirect } from "utils";

export const Recover = ({ setMode }) => {
  const [input, setInput] = useState({ email: "", password: "", code: "" });
  const [loading, setLoading] = useState(false);
  const [disabled, setDisabled] = useState(false);

  // ********************************************
  // RECOVER
  // ********************************************

  const onRecover = async () => {
    try {
      //soft validation
      if (!input.email.includes("@") || !input.email.includes(".")) {
        return errorHandler("Invalid email format.");
      }

      if (input.password.length < 8) {
        return errorHandler("Passwords must be at least 8 characters.");
      }

      //loading
      setLoading(true);

      // api request
      const { data } = await axios.post(`api/users/recover`, input);
      if (!data) throw new Error();

      //state update
      setLoading(false);
      setDisabled(true);
    } catch (error) {
      setLoading(false);
      errorHandler();
    }
  };

  // ********************************************
  // RESET
  // ********************************************

  const onReset = async () => {
    try {
      //soft validation
      if (!input.code) return;

      //loading
      setLoading(true);

      // api request
      const { data } = await axios.post(`api/users/reset`, input);
      if (!data) throw new Error();

      localStorage.setItem("session", input.email);
      windowRedirect();
    } catch (error) {
      setLoading(false);
      errorHandler();
    }
  };

  return (
    <>
      <AuthHeading title="Reset Password" />
      <AuthLogo />
      {disabled ? (
        <Alert title={input.email} intent="success">
          <br />
          <Text>
            Please see your provided email and enter your recovery code below.
          </Text>
        </Alert>
      ) : (
        <PrimaryInput
          autoFocus={true}
          label="Account Email"
          placeholder="email"
          type="email"
          disabled={disabled}
          value={input.email}
          onChange={(e) =>
            setInput((prev) => ({ ...prev, email: e.target.value }))
          }
        />
      )}

      {!disabled ? (
        <PrimaryInput
          label="New Password"
          placeholder="password"
          type="password"
          disabled={disabled}
          value={input.password}
          onChange={(e) =>
            setInput((prev) => ({ ...prev, password: e.target.value }))
          }
        />
      ) : (
        <PrimaryInput
          label="Recovery Code"
          placeholder="code"
          type="password"
          value={input.code}
          onChange={(e) =>
            setInput((prev) => ({ ...prev, code: e.target.value }))
          }
        />
      )}
      <AuthButton
        label={!disabled ? "Send Recovery" : "Reset Password"}
        isLoading={loading}
        onClick={() => {
          if (!disabled) {
            onRecover();
          } else {
            onReset();
          }
        }}
      />
      <Footer setMode={setMode} />
    </>
  );
};
