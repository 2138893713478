import { Pane } from "evergreen-ui";

export const ContactUs = () => {

  return (
    <Pane marginTop={50} paddingBottom={50}>
      <h1 style={{ fontSize: "2.3rem" }}>Contact Us</h1>
      <p>
        If you have any questions about our Terms of Service, Privacy Policy, or
        Cookies Policy, You can contact us:
      </p>
      <ul>
        <li>
          By email:{" "}
          <a href={`mailto:${process.env.REACT_APP_EMAIL}`}>
            {process.env.REACT_APP_EMAIL}
          </a>
        </li>
      </ul>
    </Pane>
  );
};
