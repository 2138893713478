
const { detect } = require('detect-browser');
export const browser = detect();
const userAgent = navigator.userAgent
export const isMobile = (/android/i.test(userAgent) || /iPhone/.test(userAgent)) 
export const isPwa = window.matchMedia("(display-mode: standalone)").matches;
export const getOS = () => {
    
    if (/android/i.test(userAgent)) {
      return "Android";
    }
  
    if (/iPhone/.test(userAgent)) {
      return "iOS";
    }
  
    return null;
  };