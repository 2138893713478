import { constants } from "constants";
import { Pane } from "evergreen-ui";

export const TermsTitle = () => {
  const { brand } = constants
  const year = new Date().getFullYear();
  
  return (
    <Pane>
      <div className="terms_title">
        <h1 style={{fontSize:'2.3rem'}}>Service Policy</h1>
        <h4>{brand}</h4>
        <h4>Last updated January 1, {year}</h4>
        <br></br>
      </div>
    </Pane>
  );
};
