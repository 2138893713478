import { Pane, Heading, PulseIcon as LogoIcon } from "evergreen-ui";
import { constants } from "constants";
import { theme } from "theme";

export const Logo = ({ textColor, fontSize, ...rest }) => {
  const { brand } = constants;
  const { colors } = theme;
  return (
    <Pane display="flex" alignItems="center" gap={5} {...rest}>
      <LogoIcon size={30} color={colors.primary} />
      <Heading color={"white"}>{brand}</Heading>
    </Pane>
  );
};
