export const constants = {
  host: window.location.origin,
  path: window.location.pathname,
  brand: "Togogo",
  url: "https://togogo.ca",
  home:"/",
  cookie: "session",
};


