import { useContext } from "react";
import { GuideContext } from "context";
import { IconButton, FloppyDiskIcon as SaveIcon } from "evergreen-ui";
import { CustomTooltip } from "components/Base";
import { theme } from "theme";
const { colors } = theme;
export const SaveBtn = ({audio, hover, setHover }) => {
  const { guide } = useContext(GuideContext)
  return (
    <CustomTooltip id="Save" tip="Save" hover={hover} top={-50} left={-7}>
      <IconButton
        disabled={!audio}
        opacity={audio ? 1 : 0.1}
        onMouseEnter={() => setHover("Save")}
        onMouseLeave={() => setHover("default")}
        icon={<SaveIcon size={25} color={colors.black} />}
        padding={20}
        onClick={async () => {
          setHover("default");

          const confirm = window.confirm(
            "You are about to download your audio tour and copy the text to your clipboard?"
          );
          if (!confirm) return;

          const downloadLink = document.createElement("a");
          downloadLink.href = audio;
          downloadLink.download = `${guide.title.split(" ").join("_")}.mp3`;
          document.body.appendChild(downloadLink);
          downloadLink.click();
          document.body.removeChild(downloadLink);
          navigator.clipboard.writeText(guide.message);
        }}
      />
    </CustomTooltip>
  );
};
