import { Pane } from "evergreen-ui";
import { CardElement } from "@stripe/react-stripe-js";

const CARD_ELEMENT_OPTIONS = {
  style: {
    base: {
      width: "100%",
      color: "#32325d",
      fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
      fontSmoothing: "antialiased",
      fontSize: "12px",
      "::placeholder": {
        color: "#aab7c4",
      },
    },
    invalid: {
      color: "#fa755a",
      iconColor: "#fa755a",
    },
  },
};

export const CardSection = () => {
  return (
    <Pane
      width="100%"
      padding={8}
      border="solid 1px lightgrey"
      borderRadius={5}
    >
      <CardElement options={CARD_ELEMENT_OPTIONS} />
    </Pane>
  );
};
