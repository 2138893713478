import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { Navbar, GlobalDialog, LandscapeAlert } from "./Layouts";
import { useStart } from "hooks";
import { isRoute } from "utils";
import { Auth, Billing, Main, Terms } from "./Views";

export const AppRoutes = () => {
  
  useStart();
  isRoute();
  return (
    <Router>
      <Navbar />
      <GlobalDialog />
      <LandscapeAlert />

      <Routes>
        <Route path="/" element={<Main />} />
      </Routes>
      <Routes>
        <Route path="/auth" element={<Auth />} />
      </Routes>
      <Routes>
        <Route path="/billing" element={<Billing />} />
      </Routes>
      <Routes>
        <Route path="/terms" element={<Terms />} />
      </Routes>
    
    </Router>
  );
};
