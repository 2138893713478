import { Pane, Heading, Text } from "evergreen-ui";
import { Logo } from "components/Base";
import { AvatarOuterBox } from "./components";
import { theme } from "theme";

export const UserAvatar = () => {
  return (
    <AvatarOuterBox>
      {localStorage.getItem("session") ? (
        <Pane display="flex" flexDirection="column" gap={5}>
          <Heading color={theme.colors.primary}>Signed in as:</Heading>
          <Text
            fontSize={12}
            color="lightgrey"
            textOverflow="ellipsis"
            whiteSpace="nowrap"
            overflow="hidden"
          >
            {localStorage.getItem("session")}
          </Text>
        </Pane>
      ) : (
        <Logo />
      )}
    </AvatarOuterBox>
  );
};
