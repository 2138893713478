import { useState, useEffect, useCallback } from "react";
import { Pane, Spinner } from "evergreen-ui";
import axios from "axios";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { Checkout } from "./components";
import { onTracker, errorHandler } from "utils";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);

export const Billing = () => {
  const [clientSecret, setClientSecret] = useState("");

  const createClientSecret = useCallback(async () => {
    try {
      // analytics
      onTracker("createClientSecret()");

      // api request
      const { data } = await axios.get(`api/stripe/create-subscription`);

      // handle error
      if (!data || data?.error) throw new Error();

      setClientSecret(data?.secret);
    } catch (error) {
      errorHandler();
    }
  }, [setClientSecret]);

  useEffect(() => {
    createClientSecret();
  }, [createClientSecret]);

  return (
    <Pane
      minHeight="100vh"
      display="flex"
      justifyContent="center"
      alignItems="center"
      paddingTop={100}
      paddingBottom={200}
    >
      <Elements stripe={stripePromise}>
        {clientSecret ? <Checkout clientSecret={clientSecret} /> : <Spinner />}
      </Elements>
    </Pane>
  );
};
