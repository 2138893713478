import { useContext } from "react";
import { Text } from "evergreen-ui";
import { GuideContext } from "context";

export const GuideContent = () => {
  const { guide } = useContext(GuideContext);

  return guide?.message ? (
    <Text fontSize="1rem" lineHeight={2} color="white">
      {guide.message}
    </Text>
  ) : null;
};
