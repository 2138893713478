import { Pane, Heading, MoreIcon, Tooltip, Position } from "evergreen-ui";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAndroid } from "@fortawesome/free-brands-svg-icons";
import { PwaIcon } from "./PwaIcon";
import { theme } from "theme";

export const Android = () => {
  return (
    <Pane
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      gap={30}
      marginBottom={45}
    >
      <Tooltip
        position={Position.TOP}
        content={`PWA's are websites that behaves like native mobile apps. Follow the instructions below to install the ${process.env.REACT_APP_BRAND} app on your Android device.`}
      >
        <Pane
          cursor="pointer"
          display="flex"
          justifyContent="center"
          alignItems="center"
          gap={10}
        >
          <FontAwesomeIcon
            size="4x"
            color={theme.colors.primary}
            icon={faAndroid}
          />
          <PwaIcon marginTop={-25} />
        </Pane>
      </Tooltip>
      <Pane
        display="flex"
        flexDirection="row"
        justifyContent="center"
        alignItems="center"
        gap={8}
      >
        <Heading>Tap</Heading>
        <MoreIcon className="rotate-90" />
        <Heading>then `Add to Home Screen`</Heading>
      </Pane>
    </Pane>
  );
};
