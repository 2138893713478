import { Pane, Alert } from "evergreen-ui";
import { isMobile } from 'utils'
import { theme } from 'theme'

export const LandscapeAlert = () => {
  const {  colors } = theme

  return (
    <Pane
      className={isMobile ? "landscape-alert" : "landscape-alert-hide"}
      zIndex={100}
      position="fixed"
      top={0}
      left={0}
      width="100%"
      height="100vh"
      background={colors.black}
      justifyContent="center"
      alignItems="center"
    >
      <Alert intent="danger" title="Landscape mode is not supported.">
        Please rotate your device back to portrait to proceed.
      </Alert>
    </Pane>
  );
};
