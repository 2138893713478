import { Pane } from "evergreen-ui";
import { theme } from "theme";

export const TitleBox = ({ children }) => {
  const { colors } = theme;


  return (
    <Pane
     
      position="absolute"
      top={20}
      left={0}
      display="flex"
      justifyContent="center"
      alignItems="center"
      width="100%"
      height={90}
      margin="auto"
      paddingX={20}
      paddingY={15}
      borderTopLeftRadius={30}
      borderTopRightRadius={30}
      background={colors.primary}
   
    >
      <Pane maxWidth="80%">{children}</Pane>
    </Pane>
  );
};
