import { createContext, useState } from "react";

export const GuideContext = createContext(null);

export const GuideProvider = ({ children }) => {

  const [userLocation, setUserLocation] = useState([0, 0]);
  const [guide, setGuide] = useState(null);
  const [showGuide, setShowGuide] = useState(null);
  const { Provider } = GuideContext;

  return (
    <Provider
      value={{
        userLocation,
        setUserLocation,
        guide,
        setGuide,
        showGuide,
        setShowGuide,
      }}
    >
      {children}
    </Provider>
  );
};
