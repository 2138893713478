import { Pane } from "evergreen-ui";

export const TourBox = ({ children }) => {
  return (
    <Pane
      id="scroll-box"
      overflowY="scroll"
      display="flex"
      flexDirection="column"
      gap={10}
      margin="auto"
      width="100%"
      maxWidth={600}
      minHeight="100vh"
      background="rgba(255,255,255,0.1)"
      padding={15}
      paddingBottom={450}
    >
      {children}
    </Pane>
  );
};
