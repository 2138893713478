import { useState } from "react";
import { Heading, LogOutIcon, Spinner } from "evergreen-ui";
import { MenuLabel } from "components/Base";
import axios from "axios";
import { windowRedirect } from "utils";
import { theme } from "theme";

export const LogoutBtn = () => {
  const [loading, setLoading] = useState();
  // *******************************
  // LOGOUT
  // *******************************
  const onLogout = async () => {
    try {
      localStorage.removeItem("session");
      setLoading(true);
      await axios.get(`api/users/logout`);
      windowRedirect();
    } catch (error) {
      localStorage.removeItem("session");
      windowRedirect();
    }
  };

  return localStorage.getItem("session") ? (
    <MenuLabel cursor="pointer" paddingBottom={60} onClick={() => onLogout()}>
      {!loading ? (
        <LogOutIcon color={theme.colors.red} />
      ) : (
        <Spinner size={18} />
      )}
      <Heading color={theme.colors.red}>- Logout</Heading>
    </MenuLabel>
  ) : null;
};
