import { Pane, Heading, Tooltip, Position } from "evergreen-ui";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faApple } from "@fortawesome/free-brands-svg-icons";
import { PwaIcon } from "./PwaIcon";

export const IOS = () => {
  return (
    <Pane
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      gap={30}
      marginBottom={45}
    >
      <Tooltip
        position={Position.TOP}
        content={`PWA's are websites that behaves like native mobile apps.  Follow the instructions below to install the ${process.env.REACT_APP_BRAND} app on your iPhone.`}
      >
        <Pane
          cursor="pointer"
          display="flex"
          justifyContent="center"
          alignItems="center"
          gap={10}
        >
          <FontAwesomeIcon
            size="4x"
            color="grey"
            icon={faApple}
          />
          <PwaIcon marginTop={-10} />
        </Pane>
      </Tooltip>
      <Pane
        display="flex"
        flexDirection="row"
        justifyContent="center"
        alignItems="center"
        gap={5}
      >
        <Heading>Tap</Heading>
        <img
          src="assets/togogo-apple-pwa-icon.png"
          alt={`${process.env.REACT_APP_BRAND} iOS`}
          width={30}
          height={30}
          style={{ border: "none", borderRadius: "50%" }}
        />
        <Heading>then `Add to Home Screen`</Heading>
      </Pane>
    </Pane>
  );
};
