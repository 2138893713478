import { useState } from "react";
import { MenuIcon } from "evergreen-ui";
import { NavBox, Logo, Menu } from "./components";
import { theme } from "theme";
const { colors } = theme;

export const Navbar = () => {
  const [showMenu, setShowMenu] = useState(false);

  return (
    <NavBox>
      <Logo />
      <MenuIcon
        cursor="pointer"
        color={colors.primary}
        size={38}
        onClick={() => setShowMenu(true)}
      />
      <Menu showMenu={showMenu} setShowMenu={setShowMenu} />
    </NavBox>
  );
};
