import { useEffect, useState } from "react";

const { addEventListener } = window;
export const useResponsive = () => {
  const [responsive, setResponsive] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
    mobile: 500,
  });
  useEffect(() => {
    addEventListener("resize", () => {
      setResponsive((prev) => ({
        ...prev,
        width: window.innerWidth,
        height: window.innerHeight,
      }));
    });
  }, []);

  return { responsive };
};
