import { Pane, Text } from "evergreen-ui";

export const CustomTooltip = ({ id, hover, tip, children, ...rest }) => {
  
  return (
    <Pane position="relative">
      {id === hover ? (
        <Pane width={20}>
          <Text
            position="absolute"
            elevation={2}
            background='lightgrey'
            color="black"
            fontWeight='bold'
            padding={12}
            borderRadius={5}
            {...rest}
          >
            {tip}
          </Text>
        </Pane>
      ) : null}
      {children}
    </Pane>
  );
};
