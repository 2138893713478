import { Pane } from "evergreen-ui";

export const GuideBox = ({ children }) => {
  return (
    <Pane
      display="flex"
      flexDirection="column"
      alignItems="center"
      paddingY={10}
      paddingTop={40}
      height="100vh"
    >
      {children}
    </Pane>
  );
};
