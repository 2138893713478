export const scrollToTop = () => {
  return setTimeout(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, 100);
};

export const scrollToBottom = () => {
  return setTimeout(() => {
    window.scrollTo({ bottom: 0, left: 0, behavior: "smooth" });
  }, 100);
};

export const scrollIntoView = (id) => {
  return document.getElementById(id).scrollIntoView({ behavior: "smooth" });
};
