import axios from "axios";
import { toaster } from "evergreen-ui";
import { onTracker, errorHandler, windowRedirect } from "utils";

export const onPoi = async (guide, setGuide) => {
  try {
    // analytics
    onTracker("onPoi()");

    const input = {
      title: guide.title,
      municipality: guide.municipality,
      region: guide.region,
      country: guide.country,
      address: guide.address,
      placeId: guide.placeId,
      coordinates: guide.coordinates,
      message: "",
    };

    const { data } = await axios.post("api/connect/poi", { input });

    const { error } = data;
    if (error) throw new Error();

    if (data === "login") {
      return windowRedirect("auth");
    }

    if (data === "billing") {
      return windowRedirect("billing");
    }

    setGuide((prev) => ({
      ...prev,
      id: data.id,
      message: data.message,
      video: data.video
    }));

    // streamingAnimation(data.message, setGuide);
    return onPoiAudio(data.id, setGuide);
  } catch (error) {
    errorHandler();
  }
};

// fetch a shared resource
export const onShare = async (input, setGuide, setShowGuide, setLoading) => {
  // analytics
  onTracker("onShare()");

  // soft validation
  if (!input) return;

  try {
    const { data } = await axios.get(`api/connect/share/${input}`);

    const { error } = data;
    if (error) throw new Error();

    setLoading(false);
    setGuide(data);
    
    setTimeout(() => {
      alert(`You are about to begin a tour of ${data.label}`);
      setShowGuide(true);
      setGuide((prev) => ({
        ...prev,
        audio: `data:audio/mp3;base64,${data.audio}`
      }))
    },1000)

  } catch (e) {
    return toaster.danger("Search Error", {
      id: "xyz",
      description: "Try a more specific address.",
      duration: 10,
    });
  }
};

// fetch audio from bucket and convert to base64 string
export const onPoiAudio = async (id, setGuide) => {
  try {
    // analytics
    onTracker("onPoiAudio()");

    const { data } = await axios.get(`api/connect/audio/${id}`);

    const { error } = data;
    if (error) throw new Error();

    if (data === "login") {
      return windowRedirect("auth");
    }

    if (data === "billing") {
      return windowRedirect("billing");
    }

    setGuide((prev) => ({
      ...prev,
      audio: `data:audio/mp3;base64,${data}`,
    }));
  } catch (e) {
    return errorHandler();
  }
};

// TEXT STREAMING ANIMATION
const streamingAnimation = (stream, setGuide) => {
  let char = 0;
  let message = "";

  function typeWriter() {
    if (char < stream.length) {
      message += stream.charAt(char);
      setGuide((prev) => ({
        ...prev,
        message: message,
      }));
      char++;
      setTimeout(typeWriter, 2);
    }
  }

  // invoke recursive function
  typeWriter();
};
