import { toaster } from "evergreen-ui";

export const onCopy = (link) => {
  
  navigator.clipboard.writeText(link);
  return toaster.success(`Copied`, {
    id: 'xyz',
    description: `A sharable link has been copied to your clipboard.`,
    duration: 8,
  });
};
