import QRCode from "react-qr-code";
import { Pane, Paragraph } from "evergreen-ui";
import { theme } from "theme";

export const QR = () => {
  return (
    <Pane textAlign="center" paddingBottom={30}>
      <Pane
        position="relative"
        display="inline-block"
        padding={8}
        paddingBottom={5}
        borderRadius={5}
        background={theme.colors.black}
      >
        <QRCode
          value={`${process.env.REACT_APP_BASE_URL}?pwa=true`}
          size={150}
          bgColor={theme.colors.black}
          fgColor={theme.colors.white}
        />
      </Pane>

      <Paragraph fontWeight="bold" color={theme.colors.black} marginTop={10}>
        Scan for mobile app
      </Paragraph>
    </Pane>
  );
};
