import { IconButton, PlayIcon, PauseIcon } from "evergreen-ui";
import { CustomTooltip } from "components/Base";
import { theme } from "theme";
const { colors } = theme;
export const PlayPauseBtn = ({
  audioElm,
  audio,
  hover,
  setHover,
  isPlaying,
  setIsPlaying,
}) => {
  // audio player controller
  const audioController = () => {
    setHover("default");
    if (audioElm.paused) {
      audioElm.play();
      setIsPlaying(true);
    } else {
      audioElm.pause();
      setIsPlaying(false);
    }
  };

  return (
    <CustomTooltip
      id="Play"
      tip={audioElm.paused ? "Play" : "Stop"}
      hover={hover}
      top={-50}
      left={-4}
    >
      <IconButton
        disabled={!audio}
        opacity={audio ? 1 : 0.1}
        onMouseEnter={() => setHover("Play")}
        onMouseLeave={() => setHover("default")}
        onClick={() => audioController()}
        padding={20}
        icon={
          !isPlaying ? (
            <PlayIcon size={25} color={colors.black} />
          ) : (
            <PauseIcon size={25} color={colors.black} />
          )
        }
      />
    </CustomTooltip>
  );
};
