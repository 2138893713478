import { useState } from "react";
import { InfoSignIcon } from "evergreen-ui";
import { CustomTooltip } from "./CustomTooltip";
import { useResponsive } from "hooks";
import { theme } from "theme";

export const Infotip = ({ id, tip }) => {
  const { colors } = theme;
  const { responsive } = useResponsive();
  const [hover, setHover] = useState("default");

  return (
    <CustomTooltip id={id} hover={hover} tip={tip} zIndex={10} width={180} top={-15} left={20}>
      <InfoSignIcon
        cursor="pointer"
        color={colors.primary}
        size={responsive.width < responsive.mobile ? 16 : 14}
        onClick={() => setHover(id)}
        onMouseEnter={() => setHover(id)}
        onMouseLeave={() => setHover("default")}
      />
    </CustomTooltip>
  );
};
