import { Pane, PulseIcon as AuthIcon } from "evergreen-ui";
import { theme } from "theme";


export const AuthLogo = ({ ...rest }) => {
  return (
    <Pane
      elevation={2}
      display="flex"
      justifyContent="center"
      alignItems="center"
      background={theme.colors.black}
      borderRadius={20}
      width={70}
      height={70}
      marginBottom={20}
      padding={10}
      {...rest}
    >
      <AuthIcon size={50} color={theme.colors.primary} />
    </Pane>
  );
};
